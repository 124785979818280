@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root {
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
}

body,
html {
  scroll-behavior: smooth !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

section {
  margin-top: 20px;
}

.store-button-icon {
  display: block;
  height: 40px;
  width: auto;
  margin: 0 auto;
  transition: transform 0.2s ease-in-out;
}

.store-button-icon:hover {
  transform: scale(1.05);
}

.legal-document-title {
  font-size: 32px;
  padding-bottom: 32px;
}

.legal-section-title {
  color: black;
  font-size: 16px;
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  font-weight: 700;
  line-height: 32.4px;
  word-wrap: break-word;
}

.legal-section-text {
  color: black;
  font-size: 12px;
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  font-weight: 300;
  line-height: 28.6px;
  word-wrap: break-word;
}

.legal-section-text-alternate {
  color: black;
  font-size: 12px;
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  font-weight: 300;
  line-height: 21.6px;
  word-wrap: break-word;
}

.legal-document-padding-y {
  @apply py-10;
}

.main-content-x-padding {
  @apply px-4 xl:px-[320px];
}
